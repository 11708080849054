.navbar {
    background-color: rgba($color: $dark, $alpha: 0.8);

    padding: 1rem 5rem;

    transition: 0.5s;

    .navbar-brand {
        color: $light;
        text-align: center;

        .logo {
            display: block;
            font-weight: bold;
            font-size: 2rem;
            color: $white;
        }

        .slogan {
            display: block;
            font-weight: normal;
            font-size: 0.75rem;
            letter-spacing: 1.2px;
        }
    }

    .navbar-toggler {
        color: $wood;
    }

    .navbar-nav {
        .nav-item {
            .nav-link {
                font-weight: bold;
                color: $light;
            }

            &.active,
            &:hover {
                .nav-link {
                    background-color: $primary;
                    border-radius: 5px;
                }
            }

            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }

    &.bg-dark {
        padding: 0.5rem 1rem;

        border-bottom: 0.5px solid rgba($color: $light, $alpha: 0.4);

        .navbar-brand {
            color: $light;
        }
    }
}

@include media-breakpoint-down(sm) {
    .navbar {
        background-color: rgba($color: $dark, $alpha: 1);
        padding: 0.5rem 1rem;

        .navbar-nav {
            border-top: 1px solid $light;
            padding-top: 0.75rem;
            margin-top: 1rem;

            .nav-item {
                .nav-link {
                    padding: 0.5rem 1rem;
                }

                &:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}
