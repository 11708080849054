.hero {
    height: 100vh;

    &__swiper-container {
        height: 100%;
    }

    &__swiper-slide {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        position: relative;

        overflow: hidden;

        &::after {
            content: "";

            width: 100%;
            height: 100%;

            background-color: rgba($color: $black, $alpha: 0.7);

            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__slide-image {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__slide-content {
        color: $white;

        padding: 1rem;

        position: relative;
        z-index: 1;

        h1,
        h2 {
            font-size: 3rem;
            padding-left: 20px;
            margin-bottom: 1.5rem;

            position: relative;

            &::before {
                content: open-quote;
                position: absolute;
                top: 0;
                left: 0;
            }

            &::after {
                content: close-quote;
            }
        }

        a {
            border-radius: 24px;
        }
    }

    &__swiper-pagination {
        .swiper-pagination-bullet {
            background: $white;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background: $primary;
        }
    }
}

@include media-breakpoint-down(md) {
    .hero {
        height: auto;

        &__swiper-container {
            height: auto;
        }

        &__slide-image {
            width: 100%;
            height: auto;

            position: static;

            img {
                display: block;
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        &__slide-content {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            h1,
            h2 {
                font-size: 2rem;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .hero {
        &__slide-content {
            top: calc(50% - 1rem);

            h1,
            h2 {
                font-size: 1rem;
                padding-left: 7px;
                margin-bottom: 1rem;
            }
        }
    }
}
