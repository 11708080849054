.service {
    $root: &;

    &__image {
        width: 100%;
        height: auto;

        margin-bottom: 1.5rem;

        border-radius: 5px;

        overflow: hidden;

        position: relative;

        transition: 0.5s ease-in-out;

        &::after {
            content: "";
            width: 100%;
            height: 100%;

            background-color: rgba($color: $secondary, $alpha: 0.3);

            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;

            z-index: 1;

            transition: 0.5s ease-in-out;
        }

        img {
            display: block;
            width: 100%;
            height: auto;

            transition: 0.5s ease-in-out;
        }

        a.link-whole {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }

        a:not(.link-whole) {
            $link-size: 56px;

            display: block;
            width: $link-size;
            height: $link-size;

            background-color: rgba($color: $concrete, $alpha: 0.8);

            visibility: hidden;

            color: $primary;

            line-height: $link-size;

            text-align: center;

            border-radius: 50%;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);

            z-index: 3;
        }
    }

    &__title {
        display: block;

        font-weight: bold;
        font-size: 1.25rem;

        color: $primary;

        text-align: center;
        text-decoration: none;

        margin-bottom: 0;

        position: relative;

        cursor: pointer;

        &:hover {
            color: $primary;

            text-decoration: none;
        }
    }

    &:hover {
        #{$root}__image {
            transform: rotateY(180deg);

            &::after {
                opacity: 1;
            }

            // img {
            // transform: scale(1.2);
            // }

            a:not(.link-whole) {
                visibility: visible;

                transform: translate(-50%, -50%);

                transition: 0.3s ease-in-out 0.5s;
            }
        }
    }
}
