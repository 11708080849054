.clients {
    padding: 5rem 0;

    &__image {
        padding: 2rem;

        img {
            display: block;
            width: 100%;
            max-width: 200px;
            height: auto;
            margin: auto;
            visibility: hidden;
        }
    }
}
