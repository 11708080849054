.iconomy {
    padding: 3rem 0 4rem;

    h1 {
        display: table;

        font-weight: bold;
        font-size: 4rem;

        text-align: center;

        padding-bottom: 1.5rem;
        margin: 0 auto 4rem;

        position: relative;

        &::before {
            content: "";
            width: 100%;
            height: 6px;

            background-color: $body-color;

            border-radius: 3px;

            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__section {
        margin-bottom: 2rem;

        h2 {
            font-weight: bold;
        }

        p {
            text-align: justify;

            b {
                font-weight: bold;
            }
        }

        ul {
            list-style: none;
            padding-left: 1.25rem;

            li {
                display: table;
                padding: 0 0 0 1.25rem;
                margin-bottom: 0.5rem;
                cursor: pointer;
                position: relative;
                transition: 0.2s ease-in-out;

                &::before {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 12px;
                    left: 0;
                    transform: translateY(-10px);
                    border: 5px solid transparent;
                    border-bottom-color: $primary;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 22px;
                    left: 0px;
                    transform: translateY(-10px);
                    border: 5px solid transparent;
                    border-top-color: $primary;
                }

                &:hover {
                    transform: translateX(10px);
                }
            }
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__iframe {
        height: 0;
        padding-bottom: 56.25%; /* 16:9 */
        position: relative;

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
