.home {
    p {
        margin-bottom: 0;

        b {
            font-weight: 700;
            font-size: 32px;
        }
    }
}
