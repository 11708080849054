.breadcrumb-container {
    background-color: #e9ecef;
}

.services {
    padding: 6rem 0;
    color: $concrete;

    &__service-wrapper {
        margin-top: 2rem;
    }
}

.service-list {
    padding: 4rem 0 3.5rem;

    &__content {
        &--desc-list {
            h2 {
                font-weight: bold;
                font-size: 1.75rem;
            }

            p {
                // font-size: 1.25rem;
                text-align: justify;
                margin-bottom: 0;

                b {
                    font-weight: bold;
                }
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    display: table;
                    margin-bottom: 1.5rem;
                    cursor: pointer;

                    h2 {
                        padding: 0 0 0 1.25rem;
                        position: relative;
                        transition: 0.2s ease-in-out;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 0;
                            top: 19px;
                            left: 0;
                            transform: translateY(-10px);
                            border: 5px solid transparent;
                            border-bottom-color: $primary;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 0;
                            top: 29px;
                            left: 0px;
                            transform: translateY(-10px);
                            border: 5px solid transparent;
                            border-top-color: $primary;
                        }

                        &:hover {
                            transform: translateX(10px);
                        }
                    }
                }
            }
        }

        &--list {
            p {
                // font-size: 1.5rem;
                text-align: justify;

                b {
                    font-weight: bold;
                }
            }

            ul {
                list-style: none;
                padding-left: 1.25rem;

                li {
                    display: table;
                    font-weight: bold;
                    // font-size: 1.25rem;
                    padding: 0 0 0 1.25rem;
                    margin-bottom: 0.5rem;
                    cursor: pointer;
                    position: relative;
                    transition: 0.2s ease-in-out;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        top: 12px;
                        left: 0;
                        transform: translateY(-10px);
                        border: 5px solid transparent;
                        border-bottom-color: $primary;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        top: 22px;
                        left: 0px;
                        transform: translateY(-10px);
                        border: 5px solid transparent;
                        border-top-color: $primary;
                    }

                    &:hover {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
}
