.footer {
    color: $light;

    text-align: left;

    padding: 6rem 0 3rem;

    &__content {
        margin-bottom: 3rem;

        h2 {
            margin-bottom: 2rem;
        }
    }

    &__info {
        a {
            // display: flex;
            // justify-content: flex-start;
            // align-items: flex-start;
            // color: $body-color;
            text-decoration: none;

            &:hover {
                // color: $light;
                text-decoration: none;
            }

            i {
                width: 16px;

                text-align: center;

                flex-shrink: 0;
                margin-top: 4px;
                margin-right: 8px;
            }
        }

        & + & {
            margin-top: 1rem;
        }
    }

    &__logos {
        display: flex;

        margin-top: 1.5rem;
    }

    &__logo {
        flex-shrink: 0;

        img {
            display: block;
            width: auto;
            height: 50px;
            margin: auto;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__social-media {
        // justify-content: center;

        li {
            margin: 0 1rem 0 0;

            a {
                font-size: 1.5rem;
            }
        }
    }

    &__copyright {
        color: white;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
    }

    &.bg-black {
        background-color: black;
    }
}
