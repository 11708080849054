// Colors
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dfe4e8;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: () !default;
$grays: map-merge(
    (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900,
    ),
    $grays
);

$wood: #856b54;
$cactus: #606d63;
$concrete: #dfe4e8;

$blue: #114094;
$red: #dc3545;
$yellow: #ffc107;
$green: #28a745;
$cyan: #17a2b8;

$colors: ();
$colors: map-merge(
    (
        "wood": $wood,
        "cactus": $cactus,
        "concrete": $concrete,
        "blue": $blue,
        "red": $red,
        "yellow": $yellow,
        "green": $green,
        "cyan": $cyan,
        "white": $white,
        "gray-light": $gray-100,
        "gray": $gray-500,
        "gray-dark": $gray-900,
        "black": $black,
    ),
    $colors
);

$primary: $wood;
$secondary: $cactus;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
    ),
    $theme-colors
);

$themeColor: $primary;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
