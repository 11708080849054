.section-header {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    color: $white;
    text-align: center;

    h1 {
        display: inline-block;

        font-weight: bold;
        font-size: 4rem;

        padding-bottom: 1.5rem;

        position: relative;

        &::before {
            content: "";
            width: 100%;
            height: 6px;

            background-color: $white;

            border-radius: 3px;

            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &--about-us {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.62),
                rgba(0, 0, 0, 0.62)
            ),
            url("../../images/pages/about-us.jpeg");
    }

    &--iconomy-two {
        height: 700px;
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.62),
                rgba(0, 0, 0, 0.62)
            ),
            url("../../images/pages/iconomy-two.jpeg");

        img {
            display: none;
        }
    }

    &--services {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.62),
                rgba(0, 0, 0, 0.62)
            ),
            url("../../images/pages/services.jpeg");
    }

    &--service {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.62),
                rgba(0, 0, 0, 0.62)
            ),
            url("../../images/pages/service.jpeg");
    }

    &--contact-us {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.62),
                rgba(0, 0, 0, 0.62)
            ),
            url("../../images/pages/contact-us.jpeg");
    }
}

@include media-breakpoint-down(sm) {
    .section-header {
        h1 {
            font-size: 2rem;
        }

        &--iconomy {
            margin-top: 0;
        }

        &--iconomy-two {
            display: block;
            height: auto;

            background-image: none;

            position: relative;

            &::before {
                content: "";
                display: block;

                width: 100%;
                height: 100%;

                background-image: linear-gradient(
                    rgba(0, 0, 0, 0.62),
                    rgba(0, 0, 0, 0.62)
                );

                position: absolute;
                top: 0;
                left: 0;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
}
